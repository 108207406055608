import React, { useLayoutEffect, useRef, useState } from 'react';
import SplitText from '../../../../components/split-text/split-text';
import styles from './hero.module.scss';
import BurgerHero from 'src/assets/images/burger-hero.png';
import gsap from 'gsap';

const Hero = () => {
  const burgerImg = useRef(null);
  const heroRef = useRef(null);
  const tl = useRef();

  const [textLoadComplete, setTextLoadComplete] = useState(false);
  const onTextLoadComplete = () => {
    console.log('complete');
    setTextLoadComplete(true);
  };

  useLayoutEffect(() => {
    console.log('layout', textLoadComplete);
    const ctx = gsap.context(() => {
      if (textLoadComplete) {
        tl.current = gsap
          .timeline()
          .fromTo(
            '.burger-img',
            {
              opacity: 0,
              y: -120
            },
            {
              opacity: 1,
              y: 0
            }
          )
          .fromTo(
            '.cta-btn',
            { opacity: 0 },
            {
              opacity: 1,
              duration: 0.5 // Call the onComplete callback when animation is complete
            }
          );
      }
    }, heroRef);

    return () => {
      ctx.revert();
    };
  }, [textLoadComplete]);

  return (
    <section className={styles.hero} ref={heroRef}>
      <div className={styles.textSection}>
        <SplitText text="Say hello to lip smacking burgers" onComplete={onTextLoadComplete} />
        <div style={{ minHeight: '44px' }}>
          <button className="cta-btn">Contact us</button>
        </div>
      </div>
      <div className={styles.imgSection}>
        <img className="burger-img" ref={burgerImg} src={BurgerHero} alt="hero" />
      </div>
    </section>
  );
};

export default Hero;
