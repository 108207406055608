import React from 'react';
import Hero from './sections/hero/hero';
import Nav from 'src/components/nav/nav';

const Home = () => {
  return (
    <div style={{ background: '#b63c3c' }}>
      <Nav />
      <Hero />
    </div>
  );
};

export default Home;
