/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import SplitType from 'split-type';
import './split-text.scss';

const SplitText = ({ text, onComplete }) => {
  const app = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      new SplitType(textRef.current);
      gsap.to('.char', {
        y: 0,
        stagger: 0.05,
        delay: 0.2,
        duration: 0.1,
        onComplete
      });
    }, textRef); // <- IMPORTANT! Scopes selector text

    return () => ctx.revert(); // cleanup
  }, []);

  return (
    <div ref={app}>
      <div className="title" ref={textRef}>
        {text}
      </div>
    </div>
  );
};

export default SplitText;
