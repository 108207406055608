import React from 'react';
import BrandLogo from 'src/assets/images/white-logo.svg';
import styles from './nav.module.scss';

const Nav = () => {
  return (
    <div className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.brandLogo}>
          <img src={BrandLogo} alt="brand-logo" />
        </div>
        <div className={styles.menu}>
          <div>
            <p>Our story</p>
          </div>
          <div>
            <p>Menu</p>
          </div>
          <div>
            <p>Order now</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
